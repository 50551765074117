<template>
  <div id="page-ladok-job-import">
    <div class="alert alert-custom alert-notice alert-light-info fade show" role="alert">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text">{{ $t('JOB.LADOK_INFO') }}</div>
    </div>

    <b-modal ref="error-popup">
      <div class="d-block text-center">
        <span class="svg-icon svg-icon-md mb-4 text-danger">
          <inline-svg
            src="/assets/svg/x-circle-fill.svg"
          ></inline-svg>
        </span>
        <p>{{ $t('LADOK.ERROR_IN_FILE') }}</p>
        <p><strong>{{ $t('LADOK.ERROR_REASON') }} </strong>{{ error_message }}</p>
      </div>
      <template #modal-footer="{ ok }">
        <b-button variant="primary" @click="ok()">{{$t('COMMON.OK')}}</b-button>
      </template>
    </b-modal>

    <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100 mb-4">
      <a
        href="#"
        class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
        @click.prevent="uploadLadokClicked"
        ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('JOB.UPLOAD_NEW_LADOK') }}</a
      >

    </div>

    <input hidden type="file" id="file" ref="file" accept=".csv" v-on:change="handleFileUpload()"/>

    <b-card v-if="current_job" class="mb-6">
      <p>{{ $t('JOB.LADOK_RUN') }} {{current_job.id}}</p>
      <p><strong>{{ $t('JOB.STATUS') }}: </strong>{{ getJobStatusStr(current_job.status) }}</p>
      <p><strong>{{ $t('JOB.ERROR_MESSAGE') }}: </strong>{{ current_job.error }}</p>
      <p><strong>{{ $t('JOB.RESULT') }}: </strong>{{ getJobResultStr(current_job.result) }}</p>
      <p><strong>{{ $t('JOB.NUM_TOTAL') }}:</strong> {{ current_job.num_records }}</p>
      <p><strong>{{ $t('JOB.NUM_PROCESSED') }}:</strong> {{ current_job.num_processed }}</p>
      <p><strong>{{ $t('JOB.NUM_OK') }}:</strong> {{ current_job.num_success }}</p>
      <p><strong>{{ $t('JOB.NUM_FAIL') }}:</strong> {{ current_job.num_fail }}</p>
      <p><b-button v-if="current_job.status === 'RUNNING'" variant="danger" @click.prevent="stop_job">{{ $t('JOB.STOP') }}</b-button></p>
    </b-card>
    <!-- LADOK jobs are using the jobs system -->
    <JobTable
      ref="jobTable"
      type="LADOK_MEMBERS"
      class="mb-8"
      @onActiveJobRunning="onActiveJobRunning"
      @jobSelected="jobSelected"
    />
 </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import JobTable from '@/view/pages/ml/jobs/JobTable.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'ladok-job-import',
  components: {
    JobTable
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods']),

  },
  mounted() {

  },
  data() {
    return {
      period:     { name: '' },
      creating:   false,
      editing:    false,
      file:       '',
      current_job: null,
      interval_id: null,

      error_message: null,


    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {

      }
    },
    currentPeriodId(newValue, oldValue) {
      this.period = this.periods.find(item => item.id === newValue);
    }
  },
  methods: {
    async stop_job() {
      try {
        this.current_job.status = 'TEMP';

        const res = await axios.put(`/job/stop/${this.current_job.id}`, {});

        if (res.status !== 200) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('JOB.UNABLE_STOP'));
          return;
        }

        this.toastr('success', this.$t('COMMON.OK'), this.$t('JOB.STOPPED'));
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('JOB.UNABLE_STOP'));
      }
    },
    jobSelected(job) {
      this.current_job = job;
    },
    onActiveJobRunning(job) {
      if (this.current_job && this.current_job.id === job.id) {
        return;
      }

      if (this.interval_id) {
        clearInterval(this.interval_id);
      }

      this.current_job = job;

      this.startPollingJob(job);
    },
    getJobResultStr(result) {
      switch (result) {
        case 'PENDING': return 'Väntar';
        case 'SUCCESS': return 'Lyckat';
        case 'FAILED': return 'Misslyckat';
        case 'SKIPPED': return 'Hoppa över';
        case 'ERROR': return this.$t('COMMON.ERROR');
      }

      return 'Okänt';
    },
    getJobStatusStr(status) {
      switch (status) {
        case 'DRAFT': return 'Utkast';
        case 'START': return 'Väntar på start';
        case 'RUNNING': return 'Igång';
        case 'FINISHED': return 'Klar';
        case 'DELETED': return 'Raderat';
      }

      return 'Okänt';
    },
    uploadLadokClicked() {
      this.file = null;
      this.current_job = null;
      this.$refs.file.value = null;

      if (this.interval_id) {
        clearInterval(this.interval_id);
      }

      this.selectFile();
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];

      try {
        const ext = this.file.name.split('.');

        if (ext[ext.length-1].toUpperCase() !== 'CSV') {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_CSV'));
          return;
        }
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_CSV'));
        return;
      }

      if (this.file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      this.uploadFile();
    },
    selectFile() {
      this.$refs.file.click();
    },
    uploadFile() {
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('file_source', 'ladok');
      formData.append('file_type', 'FILE_CSV');
      formData.append('company_id', this.currentCompanyId);

      console.log('upload file', this.file);

      axios
      .post('/fileupload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then(async res => {

        if (!res.data.fileobjs || res.data.fileobjs.length === 0) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('LADOK.UNABLE_UPLOAD'));
          return;
        }

        const res1 = await axios.post(`/job`, { type: 'LADOK_MEMBERS', options: { file_id: res.data.fileobjs[0].file_id }, data: [] });

        if (res1.status === 400) {
          this.error_message = this.$t('LADOK.FILE_ERRORS.' + res1.data.reason.toUpperCase());

          this.$refs['error-popup'].show();
          return;
        }

        if (res1.status !== 201) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('LADOK.UNABLE_CREATE'));

          this.error_message = this.$t('LADOK.UNKNOWN_ERROR');

          this.$refs['error-popup'].show();

          return;
        }

        const job = res1.data;

        const res2 = await axios.put(`/job/start/${job.id}`, {});

        if (res2.status !== 200) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('LADOK.UNABLE_START'));
          return;
        }

        this.toastr('success', this.$t('COMMON.OK'), this.$t('LADOK.STARTED'));

        this.$refs['jobTable'].refresh();

        this.current_job = job;

        this.startPollingJob(job);
      })
      .catch( err => {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('LADOK.UNABLE_UPLOAD'));
      });
    },

    startPollingJob(job) {
      this.current_job = job;
      const that = this;

      this.interval_id = setInterval(function(){
        that.pollJob();
      }, 2000);

    },

    pollJob() {
      axios
        .get(`/job/poll/${this.current_job.id}`)
        .then(res => {

          if (res.status === 200) {
            const job = res.data;

            // update the job into the jobs table
            this.$refs['jobTable'].updateJob(job);

            this.current_job = job;

            if (job.status === 'FINISHED') {
              // job is finished
              clearInterval(this.interval_id);
            }
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta körning');
            clearInterval(this.interval_id);
          }

        })
        .catch(err => {
          console.error(err);
          clearInterval(this.interval_id);
        });
    },

    downloadFile() {
      axios
        .post('/ladok/download', { ladok_id: this.form.ladok_id })
        .then( res => {
          this.form.started_at = res.data.ladok.started_at;
          this.form.export_file_id = res.data.ladok.file.file_id;
          downloadWithAxios(get_base_url() + `/dlfile/${res.data.ladok.file.linkstr}`, res.data.ladok.file.name);
        })
        .catch( err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.UNABLE_DOWNLOAD'));
        });
    },
    scrollToEditLadok() {
      var that = this;
      setTimeout(function () {
        const el = that.$el.getElementsByClassName('scroll-to-container')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },

  }
};
</script>
