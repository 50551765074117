<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <MemberShopOrderRefundModal 
        ref="refund-modal"
        :shop_order="local_item"
        @created="refund_created"
      />

      <v-tabs background-color="white" color="accent-4" left>
        <v-tab>{{ $t('SHOP_ORDER.DETAILS') }}</v-tab>
        <v-tab>{{ $t('SHOP_ORDER.PAYMENTS') }}</v-tab>

        <v-tab-item>

          <b-form ref="form" @submit.prevent="on_submit" class="mt-8">

            <b-row v-if="local_item.swish_refund_id">
              <b-col cols="12">
                <b-form-group
                  label-size="sm"
                  label-cols="4"
                  content-cols="6"
                  :label="$t('SHOP_ORDER.REFUNDED_AMOUNT')">
                  <b-form-input
                    v-model="refunded_amount"
                    :disabled="true"
                  ></b-form-input>
                    
                </b-form-group>

                <b-form-group
                  label-size="sm"
                  label-cols="4"
                  content-cols="6"
                  :label="$t('SHOP_ORDER.REFUNDED_AT')">
                  <b-form-input
                    v-model="refunded_at"
                    :disabled="true"
                  ></b-form-input>
                    
                </b-form-group>

                <b-form-group
                  label-size="sm"
                  label-cols="4"
                  content-cols="6"
                  :label="$t('SHOP_ORDER.REFUND_REASON')">
                  <b-form-input
                    v-model="local_item.refund_reason"
                    :disabled="true"
                  ></b-form-input>
                    
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-else>
              <b-col cols="12">

                <b-form-group
                  label-size="sm"
                  label-cols="4"
                  content-cols="6"
                  >
                  
                  <b-button variant="danger" @click="make_refund_clicked">{{ $t('SHOP_ORDER.MAKE_REFUND') }}</b-button>

                </b-form-group>

              </b-col>
            </b-row>


            <!--<b-row>
              <b-col cols="12">
                <b-form-group
                  label-size="sm"
                  label-cols="4"
                  content-cols="6"
                  :label="$t('SHOP_ORDER.STATUS')">

                    <b-form-select
                      @change="on_status_change"
                      v-model="local_item.status"
                      :options="order_status_options"></b-form-select>

                </b-form-group>
              </b-col>
            </b-row>-->

            <b-row>
              <b-col cols="12">
                <b-form-group
                  label-size="sm"
                  label-cols="4"
                  content-cols="6"
                  :label="$t('SHOP_ORDER.CREATED_AT')">

                    <b-form-input
                      :disabled="true"
                      v-model="local_item.created_at" />

                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group
                  label-size="sm"
                  label-cols="4"
                  content-cols="6"
                  :label="$t('SHOP_ORDER.PAYMENT_STATUS')">

                    <b-form-select
                      :disabled="true"
                      v-model="local_item.payment_status"
                      :options="payment_status_options"></b-form-select>

                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group
                  label-size="sm"
                  label-cols="4"
                  content-cols="6"
                  :label="$t('SHOP_ORDER.AMOUNT')">

                    <b-form-input
                      :disabled="true"
                      v-model="local_item.amount" />

                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group
                  label-size="sm"
                  label-cols="4"
                  content-cols="6"
                  :label="$t('SHOP_ORDER.DINTERO_ID')">

                    <b-form-input
                      :disabled="true"
                      v-model="local_item.dintero_id" />

                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group
                  label-size="sm"
                  label-cols="4"
                  content-cols="6"
                  :label="$t('SHOP_ORDER.DINTERO_TRANSACTION_DATE')">

                    <b-form-input
                      :disabled="true"
                      v-model="local_item.dintero_transaction_date" />

                </b-form-group>
              </b-col>
            </b-row>

            <p style="margin-top: 42px;"></p>


            <RightModalSaveAndCloseButtons
              class="mr-16"
              ref="create-button"
              :spin="true"
              :text="$t('COMMON.CREATE')"
              @clicked="create_submit()"
              @close="$emit('cancel')"
            />
          </b-form>

        </v-tab-item>

        <v-tab-item>
          <MemberShopOrderPaymentsTable
            class="mt-8"
            :shop_order="local_item"
          />
        </v-tab-item>

      </v-tabs>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { useVuelidate } from '@vuelidate/core'
import { minLength, required, email, requiredIf } from '@vuelidate/validators';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import MemberShopOrderPaymentsTable from '@/view/components/member_editor_v2/sections/member_shop_order/MemberShopOrderPaymentsTable.vue';
import MemberShopOrderRefundModal from '@/view/components/member_editor_v2/sections/member_shop_order/MemberShopOrderRefundModal.vue';


export default {

  name: 'MemberShopOrderEditor',

  props: ['member','item'],
  emits: ['created', 'updated', 'cancel'],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons,
    MemberShopOrderPaymentsTable,
    MemberShopOrderRefundModal
  },

  watch: {
    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };
      }
    },

  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings', 'sid']),
    refunded_amount() {
      return (this.local_item.refund_amount / 100) || 0;
    },
    refunded_at() {
      return this.local_item.refund_at ? dayjs(this.local_item.refund_at).format('YYYY-MM-DD HH:mm') : this.$t('SHOP_ORDER.NOT_REFUNDED');
    }
  },

  // --- begin vuelidate --- //
  setup () {
    return { v$: useVuelidate() }
  },

  validations () {
    return {
      local_item: {
        payment_status: { required },
        status: { required }
      }
    }
  },

  // -- end vuelidate -- //

  mounted() {

    if (this.item) {
      this.local_item = { ...this.item };
    }


    this.load_settings();

  },

  methods: {

    refund_created(shop_order) {
      this.local_item = { ...shop_order };

      this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('SHOP_ORDER.REFUND_SUCCESS'));
    },

    make_refund_clicked() {
      this.$refs['refund-modal'].show();
    },

    validate_state(state) {
      return state.dirty ? !state.error : null;
    },


    async on_submit() {


      if (valid) {

        if (!this.local_item.shop_order_id) {
          await this.create_shop_order();
        }
        else {
          await this.update_shop_order();
        }
      }

      this.$refs['create-button'].stop();
    },

    async load_settings() {
      try {
        const res = await axios.get(`/company/admin?company_id=${this.sid}`);

        if (res.status === 200 && res.data.renew) {
          this.settings = res.data.renew;
          this.email_template_id = this.settings.email_template_id;
          this.sms_template_id = this.settings.sms_template_id;
          return;
        }

      }
      catch (err) {
        console.error('load_settings', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_GET'));
    },

    async create_shop_order() {
      try {

        const res = await axios.post(`/shop_order/${this.member.member_id}`, this.local_item);

        if (res.status === 409) {

        }

        if (res.status === 201) {
          this.$emit('created', res.data);
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('SHOP_ORDER.CREATED'));
          return;
        }
      }
      catch (err) {
        console.error(err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SHOP_ORDER.UNABLE_CREATE'));
    },

    async update_shop_order() {
      try {

        const res = await axios.put(`/shop_order/${this.local_item.shop_order_id}`, this.local_item);

        if (res.status === 200) {
          this.$emit('updated', res.data);
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('SHOP_ORDER.UPDATED'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SHOP_ORDER.UNABLE_UPDATE'));
      }
    },


    getPaymentStatus(status) {
      return {
        WAIT: this.$t('COMMON.WAITING'),
        PAID: this.$t('COMMON.PAID'),
        LATE: this.$t('COMMON.LATE')
      }[status];
    },


  },


  data() {
    return {
      local_item: {},

      paying_options: [
        { value: true, text: this.$t('COMMON.PAID') },
        { value: false, text: this.$t('COMMON.NOT_PAID') }
      ],

      payment_status_options: [
        { value: 'LEGACY', text: this.$t('SHOP_ORDER.PAYMENT_STATUSES.LEGACY') },
        { value: 'FREE', text: this.$t('SHOP_ORDER.PAYMENT_STATUSES.FREE') },
        { value: 'NOT_PAID', text: this.$t('SHOP_ORDER.PAYMENT_STATUSES.NOT_PAID') },
        { value: 'PARTIALLY_PAID', text: this.$t('SHOP_ORDER.PAYMENT_STATUSES.PARTIALLY_PAID') },
        { value: 'PAID_IN_FULL', text: this.$t('SHOP_ORDER.PAYMENT_STATUSES.PAID_IN_FULL') }
      ],

      order_status_options: [
        { value: 'NONE', text: this.$t('SHOP_ORDER.STATUSES.NONE') },
        { value: 'CREATED', text: this.$t('SHOP_ORDER.STATUSES.CREATED') },
        { value: 'WAIT_PAYMENT', text: this.$t('SHOP_ORDER.STATUSES.WAIT_PAYMENT') },
        { value: 'PARTIALLY_PAID', text: this.$t('SHOP_ORDER.STATUSES.PARTIALLY_PAID') },
        { value: 'PAID_IN_FULL', text: this.$t('SHOP_ORDER.STATUSES.PAID_IN_FULL') },
        { value: 'DELETED', text: this.$t('SHOP_ORDER.STATUSES.DELETED') },
        { value: 'FAILED', text: this.$t('SHOP_ORDER.STATUSES.FAILED') },
        { value: 'DONE', text: this.$t('SHOP_ORDER.STATUSES.DONE') },
        { value: 'CANCELLED', text: this.$t('SHOP_ORDER.STATUSES.CANCELLED') }
      ],

      payment_method_options: [
        { value: 'DINTERO', text: this.$t('SHOP_ORDER.PAYMENT_METHODS.DINTERO') },
        { value: 'INVOICE', text: this.$t('SHOP_ORDER.PAYMENT_METHODS.INVOICE') },
        { value: 'FORTNOX_INVOICE', text: this.$t('SHOP_ORDER.PAYMENT_METHODS.FORTNOX_INVOICE') },
        { value: 'REDEEMCODE', text: this.$t('SHOP_ORDER.PAYMENT_METHODS.REDEEMCODE') },
        { value: 'FREE', text: this.$t('SHOP_ORDER.PAYMENT_METHODS.FREE') },
        { value: 'UNKNOWN', text: this.$t('SHOP_ORDER.PAYMENT_METHODS.UNKNOWN') },
        { value: 'bgmax', text: this.$t('SHOP_ORDER.PAYMENT_METHODS.bgmax') },
        { value: 'STRIPE', text: this.$t('SHOP_ORDER.PAYMENT_METHODS.STRIPE') }
      ],

      company_options: [],

      form: {},

      period_options: [],

      shop_items_options: [],

    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
